<template>
	<!-- Need this div to protect content from odd flexbox behavior from #page-wrap parent -->
	<div>
		<div id="header">
			<router-link :to="{ name: 'Home' }"><BaseIcon name="arthur-emblem-large"/></router-link>
			<h1>{{ entityTypeTitle }} Index</h1>

			<div class="alphabet">
				<template v-for="x in alphabet" :key="x">
					<span v-if="x == currentLetter" class="sel" :class="x == '#' ? 'hash' : ''">{{ x }}</span>
					<router-link
						v-else
						:to="{
							name: 'CatalogIndexList',
							params: { letter: x == '#' ? '123' : x },
						}"
						>{{ x }}</router-link
					>
					<br v-if="x == 'm'" class="b2 b4" />
					<br v-if="x.match(/^h|q$/)" class="b3" />
					<br v-if="x.match(/^f|t$/)" class="b4" />
					<br v-if="x.match(/^d|i|n|s|x$/)" class="b5" />
				</template>
			</div>
			<small v-if="!indexStore.loading"
				>•&nbsp;&nbsp; {{ indexStore.count }} {{ entityType }} &nbsp;&nbsp;•</small
			>
		</div>
		<BaseLoading v-if="indexStore.loading" />
		<router-view :class="{ dim: indexStore.dim }" :entities="indexStore.entities" />
	</div>
</template>

<script>
// Stores
import { useIndexStore } from '@/stores/IndexStore'
import { useApiStore } from '@/stores/ApiStore'

// Modules
import BaseIcon from '@/components/BaseIcon'
import BaseLoading from '@/components/BaseLoading'

// Internal
import { prettyNr } from '@/use/StringMagic.js'

export default {
	name: 'CatalogIndexMain',
	components: {
		BaseIcon,
		BaseLoading,
	},
	props: {
		entityType: {
			type: String,
			required: true,
		},
		letter: {
			type: String,
			required: true,
		},
	},
	setup() {
		const indexStore = useIndexStore()
		const apiStore = useApiStore()
		const indexApi = apiStore.loadApi('index')
		return { indexStore, apiStore, indexApi }
	},
	data() {
		return {
			alphabet: '#abcdefghijklmnopqrstuvwxyz'.split(''),
		}
	},
	computed: {
		// Url param 'letter' value 123 is restored to '#'
		currentLetter() {
			return this.letter == '123' ? '#' : this.letter
		},
		// entityType formatted for title
		entityTypeTitle() {
			return this.entityType.charAt(0).toUpperCase() + this.entityType.slice(1).slice(0, -1)
		},
	},

	watch: {
		letter(newLetter) {
			this.loadList(newLetter)
		},
	},

	// Server-side only: load Content
	serverPrefetch() {
		return this.loadList(this.letter)
	},

	// Client-side only: Load content
	mounted() {
		if (!this.indexStore.entities.length) this.loadList(this.letter)
	},

	methods: {
		async loadList(letter) {
			this.indexStore.startDim()
			const { status, data } = await this.indexApi.get(this.entityType, letter)
			if (status == 200) {
				await this.indexStore.storeEntities({
					entities: data.entities,
					count: prettyNr(data.count, this.$imperial),
				})
			}
			this.indexStore.stopDim()
		},
	},
}
</script>

<style lang="scss" scoped>
/* Header */
#header {
	text-align: center;
	margin: 0 auto 0.3rem auto;
	padding-top: 1rem;
}
#header h1 {
	margin: 0.3rem 0.4rem 0.6rem 0.4rem;
}
#header .letter {
	font-family: 'Berlingske Serif';
	font-size: 0.8rem;
	font-weight: 700;
}

/* Alphabet */
#header .alphabet a,
#header .alphabet span.sel {
	color: $black;
	display: inline-block;
	width: 0.4rem;
	height: 0.4rem;
	line-height: 0.4rem;
	border-radius: $br;
	// margin: 0 0.02rem;
	text-transform: uppercase;
	font-size: 0.16rem;
	font-weight: 300;
	font-family: 'Berlingske Serif';
	text-align: center;
}
#header .alphabet a.hash,
#header .alphabet span.sel.hash {
	font-family: 'Times New Roman', Times, serif;
}
#header .alphabet a:hover {
	background: $black-05;
}
#header .alphabet span.sel {
	font-size: 0.24rem;
	font-weight: 700;
	vertical-align: middle;
}
#header .alphabet br.b3,
#header .alphabet br.b4:not(.b2),
#header .alphabet br.b5 {
	display: none;
}

/* Stats */
#header small {
	display: inline-block;
	margin-top: 0.3rem;
	opacity: 0.3;
}

/**
 * Responsive logic ensures 60px side padding around the alphabet.
 */
@media only screen and (max-width: 680px) {
	#header .alphabet br.b2 {
		display: none;
	}
	#header .alphabet br.b3 {
		display: inline;
	}
}
@media only screen and (max-width: 480px) {
	#header .alphabet br.b2,
	#header .alphabet br.b3 {
		display: none;
	}
	#header .alphabet br.b4,
	#header .alphabet br.b4:not(.b2) {
		display: inline;
	}
}
@media only screen and (max-width: 428px) {
	#header .alphabet br.b2,
	#header .alphabet br.b3,
	#header .alphabet br.b4,
	#header .alphabet br.b4:not(.b2) {
		display: none;
	}
	#header .alphabet br.b5 {
		display: inline;
	}
}
</style>
